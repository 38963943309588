import React from "react"
import logoCompact from "../../static/assets/images/logo-tm.svg"

const LoadingScreen = () => {
  return (
    <div style={{ padding: 16 }} className="loading-wrapper">
      <img width={100} height={100} src={logoCompact} alt="logo" />
    </div>
  )
}

export default LoadingScreen
